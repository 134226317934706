<template>
  <div class="flex justify-center leading-loose">
    <div class="flex flex-col justify-center items-center leading-loose gap-6 w-full lg:w-1/2 p-6 mt-4">
      <img alt="گرندبیتکس"
           class="w-20"
           src="@/assets/images/logo/grandbitex-logo-400x400.png" />

      <div class="flex flex-col items-center gap-4">
        <h2 class="text-2xl font-bold font-bold dark:text-green-400">
          درباره گرندبیتکس
        </h2>

        <p class="font-medium bg-gray-50 dark:bg-gray-800 leading-loose shadow rounded p-4 md:p-6 space-y-4">
          گرندبیتکس بستری امن جهت خرید، فروش و معامله انواع ارزهای دیجیتال است،
          اگر به دنبال خرید ارز دیجیتال ارزان یا فروش آنلاین رمز ارز در ایران
          هستید، گرندبیتکس فضایی مناسب جهت معامله آنلاین ارزهای دیجیتال در ایران
          است. رمز ارزها که اغلب با نامهایی چون ارزهای رمز نگاری شده یا
          کریپتوکارنسی هم شناخته میشوند طرفداران خاصی در کشور ما پیدا کرده است،
          از همین رو گروه گرندبیتکس با تکیه بر دانش فنی خود اقدام به راه اندازی
          نوعی صرافی آنلاین ارز دیجیتال در ایران جهت خرید و فروش بیت کوین و سایر
          رمز ارز ها نموده است.
        </p>
      </div>

      <!-- <div
        class="
          grid grid-cols-1
          lg:grid-cols-3
          justify-between
          items-center
          gap-4
          w-full
          my-10
        "
      >
        <div class="flex flex-col items-center gap-6">
          <div class="text-3xl">85</div>
          <div class="text-lg">تعداد کاربران</div>
        </div>
        <div class="flex flex-col items-center gap-6">
          <div class="text-3xl">839,526</div>
          <div class="text-lg">ارزش معاملات (تومان)</div>
        </div>
        <div class="flex flex-col items-center gap-6">
          <div class="text-3xl">9</div>
          <div class="text-lg">تعداد کل معاملات</div>
        </div>
      </div> -->

      <div class="flex flex-col items-center gap-4">
        <h3 class="text-start text-2xl font-bold dark:text-green-400">
          اهداف و ارزش‌های ما
        </h3>

        <p class="font-medium bg-gray-50 dark:bg-gray-800 leading-loose shadow rounded p-4 md:p-6 space-y-4">
          ما در گرندبیتکس سعی کرده‌ایم با حذف واسطه‌ها کنترل بازار ارزهای
          رمزنگاری شده را به دست کاربران بسپاریم. در مکانیزم معاملات سایت
          گرندبیتکس قیمت گذاری توسط کاربران صورت گرفته و شما همواره با حجم زیادی
          از فروشندگان و خریداران رمز ارزها طرف هستید که میتوانید با انتخاب
          ارزان‌ترین قیمت ارز، خرید و فروش رمزارز خود را انجام دهید. البته در
          این بین گرندبیتکس با نظارت بر معاملات صورت گرفته ضمانت میکند که خریدار
          و فروشنده همیشه ارز خود را به درستی تحویل گرفته و اختلالی در امور
          معاملات ایجاد نشود. در آینده نزدیک علاوه بر رمزارزهای پشتیبانی شده
          توسط گرندبیتکس مانند بیت کوین، اتریوم، اتریوم کلاسیک، دوج کوین و ...
          امکان خرید انواع دیگر کوین ها، آلت کوین‌ها و توکن‌ها نیز در سایت
          گرندبیتکس فراهم خواهد شد. البته خدمات سایت گرندبیتکس تنها به معامله
          آنلاین بیت‌کوین و سایر رمز ارزها در ایران ختم نمی‌شود بلکه گروه
          گرندبیتکس با راه‌اندازی وبلاگ رمز ارزها سعی در افزایش دانش عمومی جامعه
          در زمینه ارزهای دیجیتال را دارد. پس اگر در زمینه معامله اینترنتی
          بیت‌کوین اطلاعات خاصی ندارید نگران نباشید وبلاگ گرندبیتکس به شما آموزش
          می‌دهد که چطور خرید بیت کوین ارزان و فروش آنلاین بیت‌کوین را تجربه
          کنید، و چگونه با استفاده از راهکارهای تحلیل تکنیکال بیت‌کوین به درآمد
          خوبی در بازار ارزهای دیجیتال برسید. و البته از کسانی که علاقمند به کسب
          اطلاعات در زمینه ارز های رمزنگاری شده هستند هم غافل نشده ایم، در این
          وبلاگ با مفاهیم پایه درباره بیت‌کوین و سایر ارزهای دیجیتال آشنا خواهید
          شد. و با پیگیری مطالب وبلاگ به یک تریدر حرفه‌ای ارزهای دیجیتال تبدیل
          میشوید. همچنین در این وبلاگ به بررسی راهکارهای استخراج بیت‌کوین در
          ایران و ماینینگ ارز دیجیتال در جهان خواهیم پرداخت و شما را با بهترین
          دستگاه‌های ماینینگ و برترین دستگاه‌های استخراج بیت‌کوین آشنا خواهیم
          کرد و روش استخراج سریع ارز دیجیتال در ایران را خواهیم آموخت. یکی دیگر
          از خدمات سایت گرندبیتکس اعلام قیمت آنلاین ارزهای دیجیتال از جمله قیمت
          جهانی بیت‌کوین و ارزان‌ترین قیمت بیت‌کوین در ایران است، پس اگر
          علاقمندید از قیمت روز بیت‌کوین آگاه باشید حتما به سایت گرندبیتکس سر
          بزنید. پس میتوان گرندبیتکس را علاوه بر یک صرافی آنلاین بیت‌کوین،
          اتریوم، لایت‌کوین و بیت‌کوین‌کش جهت خرید آنلاین ارز دیجیتال و فروش
          اینترنتی بیت‌کوین در ایران، به عنوان یک مرکز جهت تبدیل بیت‌کوین به
          ریال و یک سایت در زمینه آموزش ارزهای دیجیتال و ماینینگ بیت‌کوین در
          ایران هم معرفی کرد. امیدواریم همواره از خدمات ما لذت ببرید.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "About",
};
</script>
